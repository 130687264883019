import React from 'react'


const Input = ({
    handleChange,
    inputType,
    questionDescription,
    questionName,
    inputIcon,
    label,
    isFocused,
    setIsFocused,
    handleBlur
}) => {
    return (
        <div className="input-group " >
            {
                !label && <span className="input-group-addon"><i className={`ti-${inputIcon} text-primary`}></i></span>
            }            <label htmlFor="exampleInputEmail1" className="form-label">{label && questionName}</label>
            <input
                name={questionDescription}
                type={inputType}
                className="form-control"
                placeholder={!label && questionName}
                onBlur={handleBlur}
                onMouseEnter={() => setIsFocused(!isFocused)}
                onChange={handleChange}
            />
        </div>
    )
}

export default Input
