import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Counter from './Counter';

import bg1 from './../../images/background/bg1.jpg';

const iconBlog =[
	{
		icon: <i className="flaticon-pen" />,
		title: 'Creative Design',
		content: 'Our talented designers can tailor your brand with audio, visual, digital and physical',
	},
	{
		icon: <i className="flaticon-diamond" />,
		title: 'Precise Strategy',
		content: 'We will develop your strategy to achieve overwhelming channel domination',
	},
	{
		icon: <i className="flaticon-devices" />,
		title: 'Modern Technologies',
		content: 'We will transform your team to leverage the latest tech stack and most effective processes',
	},
];

class SectionCounter extends Component{
	render(){
		return(
			<Fragment>
				<div className="section-full content-inner-1 overlay-primary about-service bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
					<div className="container">
						<div className="section-head text-white text-center">
							<h2 className="box-title m-tb0 max-w800 m-auto">Digital Transformation</h2>
							<p>We can lead your digital transformation.  This involves modernizing your existing team with effective workforce development, upscaling your technology stack to the latest innovations, and re-wiring your strategy from the C-Suite to the staff that supports them.</p>
						</div>
					</div>
					<div className="container">
						<div className="row text-white">
							{iconBlog.map((item, index)=> (
								<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
									<div className="icon-bx-wraper bx-style-1 p-a30 center">
										<div className="icon-lg text-white m-b20"> <Link to={"#"} className="icon-cell text-white">{item.icon}</Link> </div>
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">{item.title}</h5>
											<p>{item.content}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					{/* <div className="choses-info text-white">
						<Counter />
					</div> */}
				</div>	
			</Fragment>
		)
	}
}
export default SectionCounter;