import React, { Component, useEffect, useState } from 'react';
import Header from './../Layout/header';
import Footer from './../Layout/footer';

import bgimg from './../../images/background/bg2.jpg';
import { useAddRecordMutation, useLazyGetRecordsQuery } from '../../services/agencyApi';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import { SpinnerCircular } from 'spinners-react';
import CustomForm from '../components/CustomForm';
import CustomFormSubmit from '../components/CustomFormSubmit';
import { createYupSchema } from '../components/YupSchemaCreator';

const Contact = () => {
    const [formData, setFormData] = useState()
    const [addRecord, { isLoading: isAddingRecord }] = useAddRecordMutation();
    const [isFocused, setIsFocused] = useState(false);
    const [successHandler, setSuccessHandler] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const [initialValues, setInitialValues] = useState({});


    const [
        getRecords,
        {
            data: formDataList,
            isLoading,
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        getRecords({
            entity: `Survey/GetQuestionsBySurvey/${process.env.REACT_APP_CONTACT_SURVEY}`,
        })
    }, [])
    useEffect(() => {
        setUserInfo({
            appCodeName: window.clientInformation.appCodeName,
            appName: window.clientInformation.appName,
            appVersion: window.clientInformation.appVersion,
            language: window.clientInformation.language,
            platform: window.clientInformation.platform,
            userAgentData: window && window.clientInformation && window.clientInformation.userAgentData && window.clientInformation.userAgentData.platform,
            hostname: window.location.hostname,
            host: window.location.host,
            origin: window.location.origin,
            protocol: window.location.protocol,
            referer: window.location.href,
        })
    }, [])

    useEffect(() => {
        if (formData) {
            const response = formData.questions.reduce((obj, val) => {
                return { ...obj, [`${val.questionType.name.replace(/\s/g, '')}${val.id}`]: "" }
            }, {})
            setInitialValues(response)
        }
    }, [formData])
    const yupSchema = formData && formData.questions.reduce(createYupSchema, {});
    const validationSchema = Yup.object().shape(yupSchema);

    const findInputType = (type) => {
        switch (type) {
            case 'string':
                return 'text'
            case 'email':
                return 'email'
            case 'number':
                return 'text'
            case 'Multiline Text':
                return 'textarea'
            case 'multiple-toggle':
                return 'toggle'
            case 'checkbox':
                return 'checkbox'
            default:
                break
        }
    }
    const findInputIcon = (type) => {
        switch (type) {
            case 'string':
                return 'user'
            case 'number':
                return 'mobile'
            case 'email':
                return 'email'
            case 'file':
                return 'file'
            case 'Multiline Text':
                return 'agenda'
            case 'checkbox':
                return 'checkbox'
            default:
                break
        }
    }
    const submitForm = (values) => {
        CustomFormSubmit(values, formData, addRecord, setSuccessHandler, successHandler, userInfo)
    }

    useEffect(() => {
        formDataList && setFormData(formDataList)
    }, [formDataList])
    return (
        <>
            <Header />
            <div className="full-section">
                {/* <!-- inner page banner --> */}
                <div className="dlab-bnr-inr overlay-primary-dark contact-page" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-xs-4">
                                <div className="row text-white">
                                    <div className="col-lg-12 col-md-6 m-b30" >
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                    Company Address
                                                </h5>
                                                <p>30 North Gould Street, Suite 22123, Sheridan WY, 82801 </p>
                                                <h6 className="m-b15 font-weight-400"><i className="ti-alarm-clock"></i> Office Hours</h6>
                                                <p className="m-b0">Monday-Friday - Remote Only</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6 m-b30 ">
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                                    E-mail
                                                </h5>
                                                <p className="m-b0">info@FUZIONAgency.com</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6 m-b30 ">
                                        <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                            <div className="icon-content">
                                                <h5 className="dlab-tilte">
                                                    <span className="icon-sm text-primary text-left"><i className="ti-mobile"></i></span>
                                                    Phone Number
                                                </h5>
                                                <p>317.228.5315</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer autoClose={5000} hideProgressBar />
                            <div className="col-lg-8 col-xs-8">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={submitForm}
                                    enableReinitialize={true}
                                >
                                    {(formik) => {
                                        const { errors, handleBlur, handleChange } = formik;
                                        return (
                                            <Form className="inquiry-form dzForm d-flex justify-content-center align-content-center consultation-form" action="script/contact.php">
                                                {
                                                    isLoading ? <SpinnerCircular color='white' thickness={100} secondaryColor="black" /> :
                                                        <div>
                                                            <div className="dzFormMsg"></div>
                                                            <h3 className="box-title m-t0 m-b10">{formData && formData.survey.name}<span className="bg-primary"></span></h3>
                                                            <p>{formData && formData.survey.description}</p>
                                                            <div className="row">
                                                                {
                                                                    formData && formData.questions.map((survey, index) => {
                                                                        const inputType = findInputType(survey.questionType.name);
                                                                        const inputIcon = findInputIcon(survey.questionType.name);
                                                                        var surveyName = `${survey.questionType.name}${survey.id}`.replace(/\s/g, '')

                                                                        return (
                                                                            <div key={index} className={(survey.questionType.name == 'email' || survey.questionType.name == 'Multiline Text') ? 'col-lg-12' : 'col-lg-6'}>
                                                                                <div className="form-group">
                                                                                    <div className="input-group">
                                                                                        <CustomForm
                                                                                            key={index}
                                                                                            dataValue={surveyName}
                                                                                            survey={survey}
                                                                                            errors={errors}
                                                                                            questionName={survey.name}
                                                                                            setIsFocused={setIsFocused}
                                                                                            isFocused={isFocused}
                                                                                            inputType={inputType}
                                                                                            handleChange={handleChange}
                                                                                            handleBlur={handleBlur}
                                                                                            label={false}
                                                                                            inputIcon={inputIcon}
                                                                                            questionDescription={surveyName}

                                                                                        />
                                                                                    </div>
                                                                                    {errors[surveyName] && <small className='text-danger'>{errors[surveyName]}</small>}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <div className="col-lg-12">
                                                                    <span>
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <button type="submit" name="submit" value="Submit" disabled={isAddingRecord} className="site-button button-lg d-flex justify-content-center"> {
                                                                                isAddingRecord && <SpinnerCircular color='black' size={20} thickness={100} className="mr-10" secondaryColor="white" />
                                                                            }  <span className='submit-button'>Apply for a Consultation Slot</span></button>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }

                                            </Form>
                                        )
                                    }
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- inner page banner END --> */}
            </div>

            <Footer />
        </>
    )
}
export default Contact;