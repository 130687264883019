import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { isArray } from "lodash";
import { TagType } from '../markup/components/tagFiles';


export const agencyApi = createApi({
    reducerPath: 'agencyApi',
    tagTypes: TagType,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API
    }),
    endpoints: (builder) => ({
        getRecords: builder.query({
            query: ({ entity, expand = "", columnFilter = "" }) => {
                var filterUrlString = "";
                var filterForAllColumns = { ...columnFilter }
                if (columnFilter && columnFilter !== "") {
                    filterUrlString = Object.entries(filterForAllColumns)
                        .map(([key, value]) => {
                            if (isArray(value)) {
                                var urlString = ' AND (';
                                value.map((item, index) => {
                                    index === 0 ?
                                        urlString += `${key} eq '${item}'`
                                        : urlString += ` OR ${key} eq '${item}'`
                                }
                                );
                                urlString += ")";
                                return (urlString)
                            }
                            if (typeof (value) === 'string') {
                                return (` AND contains(${key},'${value}')`)
                            }
                            return (` ${key} eq ${value}`)
                        })
                }
                if (expand !== "") {
                    filterUrlString += `&$expand=${expand}`
                }
                var newUrl = "";

                newUrl = `${entity}${filterUrlString}`
                var newUrl1 = `${entity}?$filter=IsDeleted ne true AND ${filterUrlString}`

                return {
                    url: columnFilter !== "" ? newUrl1 : `${newUrl}`,
                    method: 'GET',
                }
            },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
        addRecord: builder.mutation({
            query: (params) => ({
                url: `${params.entity}`,
                method: 'POST',
                body: params.data,
            }),
            invalidatesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
    }),
})

export const {
    useLazyGetRecordsQuery,
    useAddRecordMutation
} = agencyApi
