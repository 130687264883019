import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'/'}><span className="ti-home"></span></Link>
					</li>
					<li><Link to={'/about-1'}>About Us</Link></li>
					<li><Link to={'#'}>Channel Domination</Link></li>
					<li><Link to={'#'}>Training <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'#'}>Front End Development <i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu left">
									<li><Link to={'#'} className="fuzion-page">React UI Courses</Link></li>
									<li><Link to={'#'} className="fuzion-page">Redux Toolkit Training Courses</Link></li>
								</ul>
							</li>
							<li><Link to={'#'}>Back End Development <i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu left">
									<li><Link to={'#'} className="fuzion-page">Dotnet C# Courses</Link></li>
									<li><Link to={'#'} className="fuzion-page">WebAPI Courses</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					<li><Link to={'#'}>Our Portfolio</Link>
						<ul className="sub-menu left">
							{/* <li><Link to={'./portfolio-full-width'} className="fuzion-page">Portfolio Full Width  </Link></li> */}
						</ul>
					</li>
				</ul>
			</>
		)
	}
}
export default HeaderMenu;