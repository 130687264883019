import React from 'react'

const TextArea = ({ handleChange, questionName, questionDescription, label, inputIcon, isFocused, setIsFocused, handleBlur }) => {
    return (
        <div className="input-group">
            {
                inputIcon && <span className="input-group-addon"><i className={`ti-${inputIcon} text-primary`}></i></span>
            }
            <label>{label && questionName}</label>
            <textarea
                as='textarea'
                name={questionDescription}
                rows="4"
                className="form-control"
                placeholder={!label && questionName}
                onBlur={handleBlur}
                onChange={handleChange}
                onMouseEnter={() => setIsFocused(!isFocused)}
            >
            </textarea>
        </div>
    )
}

export default TextArea 