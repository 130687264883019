import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import ExploreCarousel from '../Element/ExploreCarousel';
import Index5Slider from '../Element/Index5Slider';

import bg17 from './../../images/background/bg17.jpg';
import bg3 from './../../images/background/bg3.png';
import bnr3 from './../../images/background/bg3.jpg';
import bg19 from './../../images/background/bg19.jpg';
import bg18 from './../../images/background/bg18.jpg';
import about11 from './../../images/about/pic11.jpg';
import { useAddRecordMutation, useLazyGetRecordsQuery } from '../../services/agencyApi';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import { SpinnerCircular } from 'spinners-react';
import CustomForm from '../components/CustomForm';
import { SURVEY_TAG } from '../components/tagFiles';
import CustomFormSubmit from '../components/CustomFormSubmit';
import { createYupSchema } from '../components/YupSchemaCreator';



const Home = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [formData, setFormData] = useState();
    const [addRecord, { isLoading: isAddingRecord }] = useAddRecordMutation();
    const [successHandler, setSuccessHandler] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const [initialValues, setInitialValues] = useState({});
    useEffect(() => {
        setUserInfo({
            appCodeName: window.clientInformation.appCodeName,
            appName: window.clientInformation.appName,
            appVersion: window.clientInformation.appVersion,
            language: window.clientInformation.language,
            platform: window.clientInformation.platform,
            userAgentData: window && window.clientInformation && window.clientInformation.userAgentData && window.clientInformation.userAgentData.platform,
            hostname: window.location.hostname,
            host: window.location.host,
            origin: window.location.origin,
            protocol: window.location.protocol,
            referer: window.location.href,
        })
    }, [])
    const [
        getRecords,
        {
            data: formDataList,
            isLoading
        }
    ] = useLazyGetRecordsQuery();
    useEffect(() => {
        getRecords({
            entity: `Survey/GetQuestionsBySurvey/${process.env.REACT_APP_FEEDBACK_SURVEY}`,
            tag: SURVEY_TAG
        })
    }, [])


    const findInputType = (type) => {
        switch (type) {
            case 'string':
                return 'text'
            case 'email':
                return 'email'
            case 'number':
                return 'text'
            case 'Multiline Text':
                return 'textarea'
            case 'multiple-toggle':
                return 'toggle'
            case 'checkbox':
                return 'checkbox'
            default:
                break
        }
    }

    useEffect(() => {
        formDataList && setFormData(formDataList)
    }, [formDataList])
    useEffect(() => {
        var i = 0;
        var inputSelector = document.querySelectorAll('input, textarea');

        for (i = 0; i < inputSelector.length; i++) {
            inputSelector[i].addEventListener('focus', function (event) {
                return this.parentElement.parentElement.classList.add("focused");
            });
        }
        for (i = 0; i < inputSelector.length; i++) {
            inputSelector[i].addEventListener('blur', function (event) {
                var inputValue = this.value;
                if (inputValue === '') {
                    this.parentElement.parentElement.classList.remove('filled');
                    this.parentElement.parentElement.classList.remove('focused');
                } else {
                    this.parentElement.parentElement.classList.add('filled');
                }
            });
        }
    }, [isFocused])

    useEffect(() => {
        if (formData) {
            const response = formData.questions.reduce((obj, val) => {
                return { ...obj, [`${val.questionType.name.replace(/\s/g, '')}${val.id}`]: "" }
            }, {})
            setInitialValues(response)
        }
    }, [formData])


    const yupSchema = formData && formData.questions.reduce(createYupSchema, {});
    const validationSchema = Yup.object().shape(yupSchema);

    const submitForm = async (values) => {
        CustomFormSubmit(values, formData, addRecord, setSuccessHandler, successHandler, userInfo)
    }

    return (
        <>
            <Header />

            <div className="page-content bg-white rubik-font">
                <div className="owl-slider-banner main-slider">
                    <Index5Slider />
                </div>
                <div className="content-block">
                    {/* <!-- About Us --> */}
                    <div className="section-full industry-service" style={{ backgroundImage: "url(" + bg17 + ")" }}>
                        <div className="container">
                            <div className="row m-b80">
                                <div className="col-lg-4 col-md-4">
                                    <div className="icon-bx-wraper ind-ser-bx">
                                        <div className="icon-lg m-b10">
                                            <Link to={"#"} className="icon-cell text-primary"><i className="flaticon-operation"></i></Link>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="dlab-tilte">The Latest Tech</h3>
                                            <p>We use the latest tech by following industry leaders like Facebook, Google, and Amazon.</p>
                                        </div>
                                        <Link to={"#"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="icon-bx-wraper ind-ser-bx active">
                                        <div className="icon-lg m-b10">
                                            <Link to={"#"} className="icon-cell text-primary"><i className="flaticon-network"></i></Link>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="dlab-tilte">Speed to Market</h3>
                                            <p>Save time by licensing our industry leading software with a trained team and mature tools.</p>
                                        </div>
                                        <Link to={"#"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="icon-bx-wraper ind-ser-bx">
                                        <div className="icon-lg m-b10">
                                            <Link to={"#"} className="icon-cell text-primary"><i className="flaticon-mind"></i></Link>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="dlab-tilte">Focus On Innovation</h3>
                                            <p>We know how to invent new business models within your existing industry.  Try our creative approach for your business.</p>
                                        </div>
                                        <Link to={"#"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-5 col-md-12 m-b30">
                                    <h2 className="box-title m-t0 m-b20 font-40"><span className="font-weight-400">About the</span><br />FUZION Agency</h2>
                                    <p className="m-b20">As perennial partners to entrepreneurial initiatives since 1998, we have deep experience in many industry verticals.  Why not read about our history and see if it's a match for your project.</p>
                                    <Link to={"./about-1"} className="site-button button-md">Read More</Link>
                                </div>
                                <div className="col-lg-7 col-md-12">
                                    <img src={about11} className="radius-sm" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- About Us End --> */}
                    {/* <!-- Why Chose Us --> */}
                    <div className="section-full bg-blue-light content-inner explore-projects" style={{ backgroundImage: "url(" + bg3 + ")" }}>
                        <div className="container">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 section-head text-center">
                                        <h2 className="m-b0 font-40"><span className="font-weight-400">Explore our </span> Projects</h2>
                                        <p className="m-b0">We have helped many companies achieve comprehensive channel domination and have led digital transformations in the Sports Industry, Mortgage Banking, Aerospace, Wholesale Distribution, Building Materials, Publishing and many more.</p>
                                    </div>
                                </div>
                                {/* <!-- blog post Carousel with no margin --> */}
                                <div className="">
                                    <ExploreCarousel />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Why Chose Us End --> */}
                    <div className="section-full overlay-black-dark our-projects" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container text-white">
                            <div className="row m-lr0 d-flex align-items-stretch">
                                <div className="col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx">
                                    <div className="ind-service-info align-self-stretch">
                                        <span>01</span>
                                        <div className="ind-service-info-in">
                                            <h2><span>The Latest</span>Tech</h2>
                                            <p>We follow the industry leaders including Facebook, Amazon, Google, and Microsoft with the latest tech stacks and the most current innovations.  We iterate constantly so we stay on top of what's coming next and we adapt as needed to keep you ahead of the game.</p>
                                        </div>
                                        <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx">
                                    <div className="ind-service-info align-self-stretch">
                                        <span>02</span>
                                        <div className="ind-service-info-in">
                                            <h2><span>Complete </span>Automation</h2>
                                            <p>If you sit for a month, the pace of innovation will pass you buy.  We automate everything... prototyping, development, deployment, testing, analysis, and iteration.  We can do automated production deployments many times per day without losing quality to keep you ahead of the competition.</p>
                                        </div>
                                        <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx">
                                    <div className="ind-service-info align-self-stretch">
                                        <span>03</span>
                                        <div className="ind-service-info-in">
                                            <h2><span>Critical</span>Strategy</h2>
                                            <p>We aren't just technologists... we're business leaders.  We have deep experience digitally transforming dozens of industry verticals so we learn how your business works first, before slinging any code.  Then we implement with ruthless precision to conquer and dominate.</p>
                                        </div>
                                        <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Our Portfolio --> */}
                    <ToastContainer autoClose={5000} hideProgressBar />
                    <div className="section-full p-tb15 our-support content-inner-2" style={{ backgroundImage: "url(" + bg19 + ")", backgroundRepeat: "no-repeat", backgroundSize: "100%", backgroundPosition: "bottom" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 section-head text-center">
                                    <h2 className="m-b0 font-40"><span className="font-weight-400">Contact</span> Us</h2>
                                    <p className="m-b0">We only accept one client per vertical due to the domination approach we take.  We are very selective in who we choose so please schedule a consultation and if we think you're a fit for our strategic assessment, we will engage and start the process.</p>
                                </div>
                            </div>

                            <div className="support-box-form bg-primary">
                                <div className="row m-lr0">
                                    <div className="col-lg-12 d-flex justify-content-center align-items-center consultation-form">
                                        <div className="support-form">
                                            {
                                                isLoading ? <SpinnerCircular color='black' thickness={100} secondaryColor="white" />
                                                    :
                                                    <div>
                                                        <div className="support-title text-white m-b30">
                                                            <h6 className="text-uppercase font-weight-500 m-b10">Apply for a Consultation</h6>
                                                            <h2 className="font-40 font-weight-400 m-tb0">{formData && formData.survey.name}</h2>
                                                            <p className="font-14">{formData && formData.survey.description}</p>
                                                        </div>
                                                        <div className="dezPlaceAni">
                                                            <div className="dzFormMsg"></div>
                                                            <Formik
                                                                initialValues={initialValues}
                                                                validationSchema={validationSchema}
                                                                onSubmit={submitForm}
                                                                enableReinitialize={true}
                                                            >
                                                                {(formik) => {
                                                                    const { errors, handleBlur, handleChange } = formik;
                                                                    return (
                                                                        <Form >
                                                                            <div className="row">
                                                                                {
                                                                                    formData && formData.questions.map((survey, index) => {
                                                                                        const inputType = findInputType(survey.questionType.name);
                                                                                        var surveyName = `${survey.questionType.name}${survey.id}`.replace(/\s/g, '')
                                                                                        return (
                                                                                            <div key={index} className={(survey.questionType.name == 'email' || survey.questionType.name == 'Multiline Text') ? 'col-lg-12 col-md-12 col-sm-12 col-12' : 'col-lg-6 col-md-6 col-sm-6 col-12'}>
                                                                                                <div className="form-group" >
                                                                                                    <div className="mb-3">
                                                                                                        <div>
                                                                                                            <CustomForm
                                                                                                                key={index}
                                                                                                                dataValue={surveyName}
                                                                                                                survey={survey}
                                                                                                                errors={errors}
                                                                                                                questionName={survey.name}
                                                                                                                setIsFocused={setIsFocused}
                                                                                                                isFocused={isFocused}
                                                                                                                inputType={inputType}
                                                                                                                handleChange={handleChange}
                                                                                                                handleBlur={handleBlur}
                                                                                                                label={true}
                                                                                                                questionDescription={surveyName}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {errors[surveyName] && <small className='text-danger'>{errors[surveyName]}</small>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <div class="spinner-border text-primary" role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>

                                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                    <button type="submit" disabled={isAddingRecord} className="site-button white button-md m-t10 d-flex justify-content-center"> {
                                                                                        isAddingRecord && <SpinnerCircular color='white' size={20} thickness={100} className="mr-10" secondaryColor="black" />
                                                                                    }  <span className='submit-button'>Submit Now</span></button>
                                                                                </div>
                                                                            </div>
                                                                        </Form>
                                                                    )
                                                                }
                                                                }
                                                            </Formik>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- Our Portfolio END --> */}
                    {/* <!-- Testimonials --> */}
                    {/* <div className="section-full content-inner ind-client" style={{ backgroundImage: "url(" + bg18 + ")", backgroundPosition:"bottom"}}>
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4 section-head">
                        <h2 className="box-title m-t0 m-b10 font-40"><span className="font-weight-400">Our</span><br /> Testimonials</h2>
                        <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                        <Link to={"#"} className="site-button button-md">View Client</Link>
                    </div>
                    <div className="col-md-8">
                        <div className="">
                            <Index5Carousel />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
                    {/* <!-- Testimonials END --> */}
                    <div className="section-full add-to-call bg-primary p-tb30">
                        <div className="container">
                            <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                <h2 className="m-b10 m-t10 text-white">Channel Domination Requires the Right Strategy</h2>
                                <div><Link to={"#"} className="site-button button-md white">Learn More</Link></div>
                            </div>
                        </div>
                    </div>
                </div >
                {/* <!-- contact area END --> */}
            </div >

            <Footer />
        </>
    )
}

export default Home;