import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../../Layout/header';
import Footer from './../../Layout/footer';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
import TestimonialCarousel from './../../Element/TestimonialCarousel';
import FormStyle from './../../Element/FormStyle';

//Images
import  about9 from './../../../images/about/pic9.jpg';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/pic1.jpg';
import services2 from './../../../images/our-services/pic2.jpg';
import services5 from './../../../images/our-services/pic5.jpg';
import services6 from './../../../images/our-services/pic6.jpg';
import bg1 from './../../../images/background/bg1.jpg';

import bnr from './../../../images/banner/bnr2.jpg';

class Aboutus1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='The FUZION of Tech and Marketing' activeMenu='About Us' />
					</div>	
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">About Us</h4>
                                    <h2 className="box-title m-tb0">We know Channel Domination<span className="bg-primary"></span></h2>
                                    <p>Each industry vertical that we target has the potential to be Uber-ed by one dominant supply chain member.  The secret to this domination is a combination of the precise strategy, the proper reach, and the latest tools.</p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box left row m-lr0 ">
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">FUZION<span className="bg-primary"></span></h2>
                                                <h4 className="text-gray-dark">Melding Tech and Creative</h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>Many clients we consult with want to "make money" on their digital transformation.  It's fun to inform them that "making" money is actually illegal, that's the Treasury's job.  There are existing rivers of money that flow in your supply chain already, it's just that your current strategy and reach hasn't managed to dip its ladel in that stream of cash in the proper ways.</p>
                                                <p>We can help.  We begin with a strategic analysis of your industry vertical to identify the flow of revenue and data through that supply chain.  We then analyze where the critical inflection points and weaknesses in the strategies of all members in that industry to identify weaknesses and opportunities.  Then we develop your transformative strategic plan so you can position yourself in the market properly to achieve complete channel domination.</p>
                                                <p>We have many examples where we have been instrumental in revenue growth, but even more importantly we can help you militarize your information technologies to have maximum impact on your competition... to your benefit.  You have more assets than you might recognize in their digital forms and we can help you identify those for maximum effect.</p>
                                                <p>This is the FUZION of technology and creative...</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <SectionCounter />
                        <VisionBlog />
                        {/* <div className="section-full content-inner" style={{ backgroundImage: "url(" + bg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- Testimonials END --> */}
                        {/* <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div> */}
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">How To Begin?<span className="bg-primary"></span></h2>
						<p>We need to be right for you, and you need to be right for us.  We have a simple process to start your digital transformation.</p>
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Discovery<span className="bg-primary"></span></h3>
								<p className="font-16">With your objectives and budget in hand, we initiate a discovery session with your leadership and learn about your business, your strategy and your current strategic assets.</p>
								<p className="font-16">We get to know how your business works.  With "day in the life" interviews, we rapidly document and deliver a comprehensive snapshot of your current business operations.</p>
								<p className="font-16">We have methods to investigate your likely competition, and with your help we compose a strategic market assessment.</p>
							</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Analysis<span className="bg-primary"></span></h3>
								<p className="font-16">
									The discovery feeds our analysis.  We begin by overlaying the digital transformational strategies that we've found to be effective into your existing strategy and approach.  We refine and simplify against how your business currently functions to give you an option for how you can proceed with your strategic digital transformation.
								</p>
								<p className="font-16">	
									We update your business process analysis with a "delta" mapping of how the new technologies will overlay and complement your workforce, your strategy, and your technology platform.
								</p>
								<p className="font-16">
									Then we perform a branding and market presence analysis to cement not only what your transformation will look like, but from a customer driven perspective, we will illustrate how your customer journey will change going forward.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services5} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Implementation<span className="bg-primary"></span></h3>
								<p className="font-16">Your team holds the core business knowledge to drive your success.  We can help with the human archeology necessary to extract and reset the value of each employee so they become powerful allies in the digital transformation.</p>
								<p className="font-16">We build centers of excellence within your company for security, architecture, implementation, innovation, and oversight with automation and simplification where necessary.</p>
								<p className="font-16">We then implement and adapt, leveraging an iterative cycle using the latest Agile methodologies to drive continuous improvement and full lifecycle automation.</p>
							</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Measurement<span className="bg-primary"></span></h3>
								<p className="font-16">
									We measure everything, with an emphasis on speed to market, value to effort, and an eye on simplification as we learn and grow from the information that comes from our measurements.
								</p>
								<p className="font-16">	
									Measurement drives re-analysis which we foster through our workforce and the processes that follow the implementation to engage in true retrospection of what was tried, how it failed/succeeded and how it performed.
								</p>
								<p className="font-16">
									Iteration then pushes the necessary priority changes to the surface with persistent and logical analysis and decision making.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services6} data-tilt alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}	

export {VisionBlog};
export default Aboutus1;