import React, {Component} from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';

const contentBlog =[
	{image: "https://res.cloudinary.com/wogd/image/upload/v1639542206/fuzion/projects/fanatics/logoFanatics_700.png", title: 'Fanatics.com', description: "We helped turn two t-shirt shops in a mall into a global sports apparel powerhouse"}, 
	{image: "https://res.cloudinary.com/wogd/image/upload/v1639543196/fuzion/projects/nasa/logoNASA_700.png", title: 'NASA.gov', description: "We helped an entire NASA contract complete a digital transformation that modernized their workforce"}, 
	{image: "https://res.cloudinary.com/wogd/image/upload/v1639543424/fuzion/projects/construction/logoHDSupply_700.png", title: 'HDSupply.com', description: "We transformed a brick and mortar civil engineering supplier into a prime acquisition target for Home Depot"}, 
	{image: "https://res.cloudinary.com/wogd/image/upload/v1639543551/fuzion/projects/sports/logoATPTour_700.png", title: 'ATPTour.com', description: "We qualified the digital media branch of the ATP Tour and the WTA Tour for large sponsorships."}, 
	{image: "https://res.cloudinary.com/wogd/image/upload/v1639543721/fuzion/projects/finance/logoFirstOption_700.png", title: 'MyFirstOption.com', description: "During the worst mortgage crisis of 2007, we tripled their business and grew their refinancing department."}, 
	{image: "https://res.cloudinary.com/wogd/image/upload/v1639544479/fuzion/projects/aerospace/logoUniversal_700.png", title: 'UniversalWeather.com', description: "We modernized the workforce for private jet aviation so they could restructure and compete on mobile."}, 
	
]

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next flaticon-right-arrow"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
		<div className="owl-nav">
			<div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{zIndex:1}}/>
		</div>
    );
} 

class ExploreCarousel extends Component{
	render(){
		var settings = {		
			arrows: true,
            slidesToShow: 3,		
            infinite: true,
			dots: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow:1,
				  }
				}
			]
        };
		return(
			<>
				<Slider className="explore-carousel mfp-gallery owl-loaded owl-theme owl-carousel gallery owl-btn-center-lr owl-btn-2 primary " {...settings}>
					{contentBlog.map((data,index)=>(
						<div className="item p-3" key={index}>
							<div className="dlab-box">
								<div className="dlab-media dlab-img-effect"> <Link to={"#"}><img src={`${data.image}`} alt="" /></Link> </div>
								<div className="dlab-info bg-white">
									<h5 className="dlab-title m-t0"><Link to={"#"}>{data.title}</Link></h5>
									<p className="m-b0">{data.description}</p>
								</div>
								<Link to={"#"} className="site-button btn-block d-flex justify-content-between white"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
							</div>
						</div>
					))}					
				</Slider>
			</>
		)
	}
	
}

export default ExploreCarousel;