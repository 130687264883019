import React from 'react'

const CustomCheckbox = ({ inputType, questionName, questionTypeId, questionId, questionDescription, values, setFieldValue, survey }) => {

    const checkBoxHandleChange = (check, index) => {
        const data = [...values[questionDescription]]
        const checkedItem = data.find((val) => {
            return val.id === index
        })
        if (checkedItem) {
            let uncheckedItem = data.filter(({ id }) => id !== checkedItem.id)
            setFieldValue(questionDescription, uncheckedItem)
        } else {
            setFieldValue(questionDescription, [...values[questionDescription],
            { id: index, optionName: check.option.name, optionId: check.option.id, questionId: questionId, questionTypeId: questionTypeId }])
        }
    }
    return (
        <div>
            <label htmlFor="exampleInputEmail1" className="form-label"><i className={`ti-check-box text-primary`}></i> {questionName}</label>
            <div role="group" aria-labelledby="checkbox-group" className='row'>
                {
                    survey.questionOption && survey.questionOption.length > 0 && survey.questionOption.map((check, index) => {
                        return (

                            <div className='col-md-6 col-lg-6'>
                                <label>
                                    <input
                                        type={inputType}
                                        name={questionDescription}
                                        value={values.questionDescription}
                                        onChange={() => checkBoxHandleChange(check, index)}
                                    />
                                    <span>{check.option.name}</span>
                                </label>
                            </div>)
                    })
                }
            </div>
        </div>
    )
}

export default CustomCheckbox