import { configureStore } from '@reduxjs/toolkit'
import { agencyApi } from '../services/agencyApi'


export const store = configureStore({
    reducer: {
        [agencyApi.reducerPath]: agencyApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(agencyApi.middleware)
})
